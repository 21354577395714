import { createContext, useReducer } from "react";

const initialState = {
    isInitialized: false,
    bannerMessage: false,
    isAuthenticated: false,
    user: {},
    step: 24 * 60 * 60,
};

const AppContext = createContext(initialState);
const { Provider } = AppContext;

const reducer = (state, action) => {
    switch (action.type) {
        case "INIT":
            const user = localStorage.getItem("user") || null;
            return {
                ...state,
                isAuthenticated: user ? true : false,
                isInitialized: true,
                user: JSON.parse(user),
            };
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.payload.user));
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case "LOGOUT":
            localStorage.removeItem("user");
            return {
                ...initialState,
                isAuthenticated: false,
            };
        case "BANNER_MESSAGE":
            return {
                ...state,
                bannerMessage: action.payload,
            };
        case "STEP":
            return {
                ...state,
                step: action.payload,
            };
        default:
            return state;
    }
};

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AppContext, AppProvider };
