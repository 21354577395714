import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "store";

function Header() {
    const { dispatch } = useContext(AppContext);

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-custom">
                <Nav className="navbar-nav">
                    <Nav.Item>
                        <Nav.Link
                            as={Link}
                            to="/"
                            className="brand-link dashboard-logo"
                            alt="papperdata"
                        ></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav className="navbar-nav ml-auto">
                    <NavDropdown
                        className="user-menu"
                        title={
                            <span>
                                <i>
                                    <FontAwesomeIcon icon={["fas", "gear"]} />
                                </i>
                            </span>
                        }
                    >
                        <NavDropdown.Item
                            onClick={() => {
                                dispatch({
                                    type: "LOGOUT",
                                });
                            }}
                        >
                            Demo Setup
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </nav>
        </>
    );
}

export default Header;
