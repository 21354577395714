import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "components/ScrollToTop";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "store";
import Auth from "containers/Auth";
import "react-toastify/dist/ReactToastify.css";
import "./adminlte.css";
import "./index.css";
import "./pepperdata.css";

const history = createBrowserHistory();

library.add(fas);

ReactDOM.render(
    <>
        <React.StrictMode>
            <Router history={history}>
                <ScrollToTop />
                <AppProvider>
                    <Auth />
                </AppProvider>
            </Router>
        </React.StrictMode>

        <ToastContainer />
    </>,
    document.getElementById("root")
);
