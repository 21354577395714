import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppContext } from "store";

const PageBanner = () => {
    const {
        state: { bannerMessage },
        dispatch,
    } = useContext(AppContext);

    if (!bannerMessage) return null;

    return (
        <div className="page-banner">
            <div className="page-banner-messages">
                <div className="pd-banner pd-banner-warn">
                    <div className="pd-banner-content">
                        <div className="pd-banner-icon">
                            <i>
                                <FontAwesomeIcon
                                    icon={["fas", bannerMessage?.type]}
                                />
                            </i>
                        </div>
                        <div className="pd-banner-children">
                            {bannerMessage?.message}
                        </div>
                        <div
                            className="pd-banner-close"
                            onClick={() => {
                                dispatch({
                                    type: "BANNER_MESSAGE",
                                    payload: false,
                                });
                            }}
                        >
                            <i>
                                <FontAwesomeIcon icon={["fas", "times"]} />
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageBanner;
