import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { closeSidebar } from "utils/helpers";

function Sidebar() {
    return (
        <aside className={`main-sidebar sidebar-dark-primary elevation-4`}>
            <div className="sidebar">
                <nav>
                    <Nav className="nav nav-pills nav-sidebar flex-column primary-nav">
                        <Nav.Item className="primary-nav-item">
                            <Nav.Link as={Link} to="/" onClick={closeSidebar}>
                                <i className="nav-icon">
                                    <FontAwesomeIcon
                                        icon={["fas", "chart-pie"]}
                                    />
                                </i>
                                <p className="nav-icon-label">
                                    <span>Dashboards</span>
                                </p>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </nav>
            </div>
        </aside>
    );
}

export default Sidebar;
