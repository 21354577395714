import { Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import Dashboard from "containers/Dashboard/Loadable";
import ClusterDetail from "containers/ClusterDetail/Loadable";
import { Redirect } from "react-router-dom";
import Login from "containers/Login/Loadable";
import Layout from "components/Layout";

export const AppRoute = () => {
    return (
        <QueryParamProvider ReactRouterRoute={Route}>
            <Layout>
                <Switch>
                    <Route
                        path="/instance-optimizer/:id/"
                        component={ClusterDetail}
                    />
                    <Route path="/instance-optimizer/" component={Dashboard} />
                    <Redirect from="*" to="/instance-optimizer/" />
                </Switch>
            </Layout>
        </QueryParamProvider>
    );
};

export const AuthRoute = () => {
    return (
        <div className={"login-page-container"}>
            <section className="content">
                <div className="container-fluid">
                    <div>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </div>
                </div>
            </section>
        </div>
    );
};
