import Header from "components/Layout/Header";
import queryString from "query-string";
import Sidebar from "components/Layout/Sidebar";
import PageBanner from "./PageBanner";
import { useContext, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation } from "react-router-dom";
import { AppContext } from "store";

const queryConfig = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 60000,
        },
    },
};

const queryClient = new QueryClient(queryConfig);

function Layout({ children }) {
    const { dispatch } = useContext(AppContext);
    const { search } = useLocation();
    const { plain } = queryString.parse(search);

    useEffect(() => {
        if (plain) document.body.classList.add("plain");
    }, [plain]);

    useEffect(() => {
        dispatch({
            type: "BANNER_MESSAGE",
            payload: {
                message:
                    "Cluster is running multiple versions of Pepperdata software.",
                type: "warning",
            },
        });
    }, [dispatch]);

    return (
        <QueryClientProvider client={queryClient}>
            {!plain && <Header />}
            {!plain && <Sidebar />}
            <div className={"content-wrapper middle-section"}>
                <section className="content w-100">
                    <div className="container-fluid">
                        <PageBanner />
                    </div>
                    <div className="container-fluid">{children}</div>
                </section>
            </div>
        </QueryClientProvider>
    );
}

export default Layout;
