export const environment = {
    apiURL: process.env.REACT_APP_BRDG_API_ENDPOINT || "",
    version: process.env.REACT_APP_VERSION || "1.0.0",
};

export const closeSidebar = () => {
    document.getElementsByTagName("BODY")[0].classList.add("sidebar-collapse");
};

export const parseCurrency = (cur) => {
    let tmpCur = parseFloat(cur).toFixed(2);
    tmpCur = tmpCur.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return tmpCur === "NaN" ? cur : `$${tmpCur}`;
};

export function parseBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 B";
    let isNegative = false;
    if (bytes < 0) {
        bytes = Math.abs(bytes);
        isNegative = true;
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
        (isNegative ? "- " : "") +
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
        " " +
        sizes[i]
    );
}

export function calcYDomain(seriesData) {
    const allPoints = seriesData
        .map((series) => series.data.map((d) => parseFloat(d.y)))
        .flat();
    const min = Math.min(...allPoints);
    const max = Math.max(...allPoints);
    const deltaMax = max + max * 0.1;
    const deltaMin = min + min * 0.1;
    return [deltaMin, deltaMax];
}
