import logo from "images/pepper_loading.gif";

const loadingContainerstyle = {
    margin: "2em auto",
    width: "150px",
    height: "150px",
    position: "relative",
};

function LoadingIndicator() {
    return (
        <div style={loadingContainerstyle}>
            <img src={logo} alt="Loading.." style={{ width: "100%" }} />
        </div>
    );
}

export default LoadingIndicator;
