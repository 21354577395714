import { useMountEffect } from "@react-hookz/web";
import LoadingIndicator from "components/LoadingIndicator";
import { AppRoute, AuthRoute } from "routes";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "store";

function Auth() {
    let history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const [isLoading, setisLoading] = useState(true);

    useMountEffect(() => {
        dispatch({
            type: "INIT",
        });
    });

    useEffect(() => {
        if (!state.isInitialized) return;

        if (!state.isAuthenticated) {
            const { href, pathname } = window.location;
            setisLoading(false);

            if (pathname !== "/")
                return history.push({
                    pathname: "/",
                    search: `?from=${encodeURIComponent(href)}`,
                });

            return history.push("/");
        }

        setisLoading(false);
    }, [state.isInitialized, state.isAuthenticated, state.user]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) return <LoadingIndicator />;

    if (!state.isAuthenticated) return <AuthRoute />;

    if (state.isAuthenticated) return <AppRoute />;
}

export default Auth;
